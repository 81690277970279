@import './../../../styles/common';
@import './../../CustomAmountComponent/styles';

.custom-amount-view-component {
  p {
    font-size: $font-size-20;
    color: $andes-gray-900;
    text-align: center;
    line-height: $andes-spacing-20;
    margin: 0;
  }

  .important-text {
    font-size: $font-size-14;
    line-height: $andes-spacing-20;
    color: $andes-gray-550;
  }

  .side-text {
    margin-top: 16px;
    font-size: $font-size-14;
    color: $andes-gray-550;
  }
}
