@import '../../../styles/common';
@import '../../SafePaymentMessage/styles';

.disclaimer-links {
  margin-top: $andes-spacing-12;

  span {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    text-align: center;
    margin-left: $andes-spacing-8;
    font-size: $font-size-12;
    color: $andes-gray-550;
  }

  a,
  a:hover,
  a:active {
    color: $andes-blue-500;
    margin-left: $font-size-12;
    text-decoration: none;
    white-space: nowrap;

    &:first-of-type {
      margin-left: 0;
    }
  }
}
