.safe-payment-message {
  display: flex;
  justify-content: center;
  margin-top: $andes-spacing-24;

  span {
    margin-left: 6px;
    font-size: $font-size-12;
    color: $andes-gray-550;
  }
}
