.user-info {
  display: flex;
  font-size: $font-size-14;
  line-height: em(28, 14);

  &__text {
    display: flex;
    color: $andes-gray-550;
  }

  .user-avatar {
    margin-left: em(10, 14);
  }

  .ui-avatar__content {
    width: em(28);
    height: em(28);

    img {
      width: 100%;
      height: 100%;
    }
  }

  .icon-wrapper {
    width: em(28, 14);
    height: em(28, 14);
    margin-left: em(8, 14);
  }
}
