@import 'shared';

.brand {
  padding: 32px 0;

  &__img {
    width: 44px;
    height: 44px;
  }

  &__name {
    font-size: $font-size-24;
  }
}
