@import './shared';

.modal-billing .andes-modal {
  &__header,
  &__actions {
    padding: $andes-spacing-24;
    padding-bottom: em(16);
  }

  &__content {
    padding: 0 $andes-spacing-24;
    height: 248px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .modal__description {
      margin-bottom: $andes-spacing-16;
      text-align: center;

      p {
        font-size: $font-size-16;
        color: $andes-gray-550;
      }
    }
  }
}

.andes-modal--large .andes-modal__title {
  font-size: $font-size-24;
}

.andes-modal--large .andes-modal__close-button {
  svg path {
    fill: $andes-blue-500;
  }
}
