@import '../../../styles/common';
@import '@andes/card/index';
@import '../../AmountFormat/styles';
@import '../../TagSpecialMessage/styles/shared';

.fix-amount-view-component {
  font-family: $font-family-primary;

  p {
    font-size: $font-size-16;
    color: $andes-gray-550;
    text-align: center;
    margin: 0;
  }

  .important-text {
    max-width: 220px;
    margin: auto;
    font-size: $font-size-14;
    line-height: $andes-spacing-20;
    color: $andes-gray-550;
  }

  .side-text {
    margin-top: 16px;
    font-size: $font-size-12;
    color: $andes-gray-550;
  }

  .amount-container {
    margin: 0 auto $andes-spacing-16;
    font-size: $font-size-32;
    font-weight: $font-weight-semibold;
    color: $andes-gray-900;
    text-align: center;
  }
}
