@import './shared';
@import './../../../components/HeaderComponent/styles.desktop';
@import './../../../components/CustomAmountViewComponent/styles/styles.desktop';
@import './../../../components/UserHeader/styles/styles.desktop';
@import './../deviceLayouts/desktopLayout/styles';
@import './../../../components/DisclaimerLinks/styles/styles.desktop';
@import './../../../components/CheckboxTYC/styles/styles.desktop';
@import './../../../components/ModalBillingExplanation/style.desktop';

body {
  padding-top: 116px;
}

.card-container {
  max-width: 580px;
  margin: 0 auto;
}

.greet {
  max-width: 366px;
  font-size: $font-size-16;
}

.info-texts {
  margin-bottom: $andes-spacing-40;
}

.andes-card--padding-default .andes-card__content {
  padding: 0 52px 48px;
}

.init-point {
  position: relative;
  padding-bottom: $andes-spacing-48;
}
