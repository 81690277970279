.tag-special-message {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: fit-content;
  height: 28px;
  border-radius: 14px;
  padding: 0 $andes-spacing-8;
  margin: $andes-spacing-8 $andes-spacing-4 $andes-spacing-32;

  .tag-label {
    font-size: $font-size-12;
  }

  .tag-image {
    width: 20px;
    margin-right: 8px;
  }

  &__green {
    border: 1px solid $andes-success-green;
    color: $andes-success-green;
  }

  &__lightblue {
    border: 1px solid;
    color: $andes-blue-500;
  }
}
