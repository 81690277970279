@import '@andes/card/index';
@import '@andes/spinner/index';
@import '@andes/snackbar/index';
@import './../../../styles/common';
@import './../../../components/FooterComponent/styles';
@import './../../../components/FixAmount/styles/shared';
@import './../../../components/UserHeader/styles/shared';
@import './../../../components/TagSpecialMessage/styles/shared';
@import './../../../components/ModalBillingExplanation/shared';

@import '@andes/checkbox/index';

.init-point {
  .header-comp {
    background-color: $andes-gray-100;
    min-height: 8vh;
  }

  .body-comp {
    min-height: 80vh;
  }

  p {
    color: $andes-gray-900;
  }

  .greet {
    margin: 0 auto $andes-spacing-32;
    color: $andes-gray-900;
    text-align: center;
  }

  .andes-snackbar__message {
    color: $andes-white;
  }

  .tags-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .billing-explanation-trigger {
    display: flex;
    color: $andes-blue-500;
    justify-content: center;
    margin-bottom: 40px;
    margin-top: -24px;
    font-size: $font-size-16;
    cursor: pointer;

    span {
      margin-left: 8px;
    }

    svg {
      margin-top: 2px;
    }

    &-disabled {
      color: #eee;

      svg path {
        fill: #eee;
      }
    }
  }

  .important-text-small {
    margin: 0 $andes-spacing-32 $andes-spacing-32;
    font-size: $font-size-12;
    color: $andes-gray-550;
    text-align: center;
  }

  .message_shared_data {
    margin: 0 $andes-spacing-16 $andes-spacing-16;
    font-size: $font-size-12;
    color: $andes-gray-550;
    text-align: center;
  }
}
