@import './../../UserInfo/styles/shared';
@import './../../GroupMenu/styles/shared';

.user-header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background-color: $andes-white;
  z-index: 99;
  box-shadow: 0 2px 8px 0 $andes-gray-100;

  &__content {
    display: flex;
    justify-content: space-between;
    padding: 14px 32px;
    max-width: 50.75em;

    .icon-wrapper.icon-mercadopago-full {
      width: 120px;
      flex-grow: 1;
      justify-content: start;
    }
  }

  .info-row {
    display: flex;
    flex-direction: column;

    .info-row__title {
      color: $andes-gray-550;
    }

    .info-row__detail {
      color: $andes-gray-550;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: em(228);
    }
  }

  a {
    color: $andes-blue-500;
    text-decoration: none;
    cursor: pointer;
  }

  .option-login {
    color: $andes-blue-500;
    border: 1px solid $andes-blue-500;
    border-radius: 4px;
    height: 36px;
  }
}

.icon-wrapper {
  width: 36px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    flex-shrink: 0;
  }
}
