.milestones-container {
  height: 122px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* milestones */
ul.milestones {
  width: 324px;
  padding-left: 16px;
  margin: 0;
}

ul.milestones li {
  padding-left: 0;
  list-style: none;
}

.milestone-child {
  display: flex;
  align-items: center;

  .tag-special-message {
    margin: 0;
  }

  &__date {
    width: 32px;
    margin-right: 16px;
    font-size: $font-size-12;
    color: $andes-gray-550;
  }

  &__amount {
    width: 84px;
    margin-right: 8px;
    font-size: $font-size-14;
    font-weight: $font-weight-bold;
    color: $andes-gray-900;
  }
}

/* BORDERS AND BULLETS */
p {
  margin-bottom: 0;
}

ul.milestones li {
  position: relative;
  margin-bottom: 0;
  padding-bottom: 10px;
}

ul.milestones li::after {
  /* bullets */
  position: absolute;
  left: -20px;
  top: 12px;
  content: '';
  height: 9px;
  width: 9px;
  background-color: $andes-blue-500;
  border-radius: 50%;
  display: inline-block;
}

ul.milestones li::before {
  /* lines */
  content: '';
  position: absolute;
  left: -16px;
  border-left: 1px solid black;
  height: 100%;
  width: 1px;
}

ul.milestones li:first-child::before {
  /* first li's line */
  top: 18px;
}

ul.milestones li:last-child::before {
  /* last li's line */
  height: 30px;
  bottom: 22px;
}

ul.milestones li:only-child::before {
  /* one li's line */
  height: 0;
}
