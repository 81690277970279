@import './../../styles/common';
@import '@andes/button/index';
@import './../../../app/components/SafePaymentMessage/styles';

.footer-component {
  padding: $andes-spacing-16 $andes-spacing-24;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  transition: background 250ms ease, box-shadow 250ms ease;
  z-index: 1000;

  &.with-background {
    background-color: $andes-white;
    box-shadow: $shadow-elevated;
  }

  .safe-payment-message {
    margin-top: $andes-spacing-16;
  }
}

.footer-component__desktop {
  text-align: center;

  .andes-button--full-width {
    display: inline-block;
    min-width: 238px;
    max-width: fit-content;
  }
}
