.checkbox-tyc {
  padding: 0 $andes-spacing-20 $andes-spacing-24;
  margin: 0 auto;
  max-width: 345px;

  .verify-checkbox {
    display: flex;
    user-select: none;

    a {
      color: $andes-blue-500;
    }

    .andes-checkbox__mimic {
      width: $andes-spacing-20;
    }

    .andes-checkbox__input {
      margin: 3px 5px 0 0;
    }

    .andes-checkbox__label.andes-checkbox__label-text {
      color: $andes-gray-550;
      font-size: $font-size-12;
      text-align: left;
      line-height: $line-height-m;
      margin-left: $andes-spacing-12;
    }
  }
}
