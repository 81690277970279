@import '@panel/avatar/styles/_styles.shared';
@import '@panel/avatar/styles/mobile';

.brand {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $andes-gray-100;

  &__img {
    width: 42px;
    height: 42px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    box-shadow: $shadow-flat;

    img {
      height: 100%;
      display: block;
    }
  }

  &__name {
    max-width: 200px;
    margin-left: 10px;
    font-weight: $font-weight-semibold;
    line-height: 1;
    font-family: $font-family-primary;
    word-break: break-word;
    color: $andes-gray-900;

    &--small {
      font-size: $font-size-18;
    }

    &--large {
      font-size: $font-size-20;
    }
  }

  .ui-avatar__content--initials {
    width: 42px;
    height: 42px;
    border: 0.0625em solid $brand-main;
    background: $avatar-background;
    line-height: 2.5em;
    text-align: center;
  }
}
