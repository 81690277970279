@import './../../styles/common';
@import '@andes/card/index';
@import '@andes/form/index';
@import '@andes/textfield/index';

.custom-amount-component {
  margin: 0 auto $andes-spacing-16;

  .shrink-container {
    max-width: 260px;
    margin-left: auto;
    margin-right: auto;
  }

  .money-options {
    display: flex;
    width: calc(100% + 10px);
    margin-left: -5px;
    flex-wrap: wrap;

    .money-option {
      cursor: pointer;
      flex: 1;
      margin: 5px;
      border-radius: 16px;

      .andes-button {
        color: $andes-black;
        font-weight: $font-weight-regular;
        border-radius: 16px;
        border: solid 1.2px $andes-gray-550;
      }

      .andes-button:hover {
        border-color: $andes-blue-500;
      }

      .andes-button:active {
        border-color: $andes-blue-500;
      }
    }
  }
}

.money-container {
  position: relative;
  margin: 0 $andes-spacing-8;
  min-height: 75px;

  .andes-form-control {
    &--textfield {
      display: inline-block;
      width: 100%;
      padding-top: 0;
    }

    &--textfield input {
      font-size: $font-size-32;
      height: auto;
      font-weight: $font-weight-semibold;
      margin: 0;
      color: $andes-blue-500;
    }

    &__message {
      font-size: 10px;
      min-height: 12px;
    }

    .andes-form-control__control {
      box-shadow: none !important;
      border-bottom: solid 1px $andes-gray-550;
      border-radius: 0;
      justify-content: center;
    }

    .andes-form-control__field {
      padding: 0;
    }

    svg {
      display: none;
    }

    &--error .andes-form-control__control {
      border-bottom: solid 1px $andes-error-red-secondary;
    }

    &--focused .andes-form-control__control {
      border-bottom: solid 1px $andes-blue-500;
    }

    &.andes-form-control--error.andes-form-control--focused
      .andes-form-control__control {
      border-bottom: solid 1px $andes-error-red;
    }
  }

  .label {
    color: $andes-gray-550;
    position: absolute;
    top: 25px;
    font-size: 12px;
  }
}
