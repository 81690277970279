$andes-theme: 'mercadopago';

$container-max-width: 580px;
$default-background: #eee;

$avatar-background: #eff8fe;
$card-background-gradient-from: #5e8aa0;
$card-background-gradient-to: #3a4e64;
$card-background: linear-gradient(
  201deg,
  $card-background-gradient-from 0%,
  $card-background-gradient-to 100%
);
