@import './variables';

// Andes components
@import '@andes/common/index';
@import '@andes/modal/index';
@import '~@andes/badge/index';
@import '~@andes/message/index';

// Base
@import '@andes/progress-indicator-circular/index';

// Base
body {
  margin: 0;
  padding: 0;
  background: $default-background;
  font-family: $font-family-primary;
  line-height: 1.25;
}

// Commons Components
